import React, { lazy, useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Loader from "../utils/Loader";
import { useNavigate } from "react-router-dom";

const SignIn = lazy(() => import("../components/Auth/SignIn"));
const SignUp = lazy(() => import("../components/Auth/SignUp"));
const ForgetPassword = lazy(() => import("../components/Auth/ForgetPassword"));
const NewPassword = lazy(() => import("../components/Auth/NewPassword"));

export default function Index() {
  const [user, setUser] = useState(false);
  let navigate = useNavigate();
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    console.log("user", user);
    if (user?.role) {
      setUser(true);
    } else {
      setUser(false);
    }
  }, [user]);
  return (
    <>
      {user ? (
        navigate("/user/home")
      ) : (
        <React.Suspense fallback={<Loader active={true} />}>
          <Routes>
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/new-password" element={<NewPassword />} />
          </Routes>
        </React.Suspense>
      )}
    </>
  );
}
