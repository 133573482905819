import axios from "../axiosConfig";

class Routes {
  login(data) {
    return axios.post("/login", data);
  }
  register(data) {
    return axios.post("/register", data);
  }
  logout() {
    return axios.post("/logout");
  }

  //forget password
  //send code to email
  sendCodetoEmail(email) {
    return axios.post(`/password-reset?email=${email}`);
  }
  verifyCode(data) {
    return axios.post(`/verify-password`, data);
  }
  changePassword(data) {
    return axios.post(`/change-password`, data);
  }

  //social auth
  signinWithGoogle(data) {
    return axios.post(`/auth-callback`, data);
  }
}

export default new Routes();
