import axios from "../axiosConfig";

class Routes {
  buyerTransections() {
    return axios.get("/buyer/list");
  }
  changeBuyerStatus(id) {
    return axios.put(`/set-status/${id}`);
  }

  //buyer Dashboard
  buyerAllBids() {
    return axios.get("/buyer/user/bids");
  }
  buyerWonBids() {
    return axios.get("/buyer/won/bids");
  }
  buyerFavourteBids() {
    return axios.get("/buyer/favouriate-product");
  }
  buyerChangeEmail(data) {
    return axios.put("/buyer/change-email", data);
  }
  buyerChangePass(data) {
    return axios.put("/buyer/change-password", data);
  }

  //Get full user
  getUser(id) {
    return axios.get(`/user/${id}`);
  }
  //update user
  updateUser(id, data) {
    return axios.post(`/user/${id}/update`, data);
  }
}

export default new Routes();
