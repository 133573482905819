import axios from "../axiosConfig";

class Routes {
  allCategories() {
    return axios.get("/home/category");
  }
  productsByCategories(id) {
    return axios.get(`/home/all-products/${id}`);
  }
  allProductsByCategories(id) {
    return axios.get(`/home/all-products`);
  }
}

export default new Routes();
