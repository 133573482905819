import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import InputField from "../../../utils/InputField";
import Button from "../../../utils/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { BuyerAPI } from "../../../api";
import { toast } from "react-toastify";

import addImg from "../../../assets/images/addImg.png";
function UpdateProfile() {
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const [user, setUser] = useState(null);
  const [avatar, setAvatar] = React.useState(null);
  const [picture, setPicture] = React.useState(null);

  const handleChange = (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.addEventListener("load", (e) => {
      setAvatar(e.target.result);
      let data = e.target.result.split(",")[1];
      setPicture(data);
      return data;
    });
    reader.removeEventListener("load", (e) => setAvatar(e.target.result));
  };
  const initialValues = {
    username: "",
    phone_number: "",
  };

  const ValidationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    phone_number: Yup.string().required("Phone Number is required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: onSubmit,
  });
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let id = user.id;
    BuyerAPI.getUser(id).then((res) => setUser(res.data.data));
  }, []);

  useEffect(() => {
    if (user) {
      formik.setFieldValue("phone_number", user?.user_detail?.phone_number);
      formik.setFieldValue("username", user?.username);
      if (user?.image) {
        setAvatar(`${process.env.REACT_APP_HOST}${user?.image?.url}`);
      }
    }
  }, [user]);

  function onSubmit(values) {
    values.image = picture;
    values._method = "put";
    console.log(" values", values);
    BuyerAPI.updateUser(user.id, values).then((res) =>
      toast.success("Profile Updated")
    );
    // BuyerAPI.buyerChangeEmail(values).then((res) => console.log("res", res));
  }
  return (
    <Box
      sx={{
        pt: { xs: 4, md: 15 },
        // pb: { xs: 6, sm: 10, md: 9 }
      }}
    >
      <Box component="form" onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={2}
          sx={{ px: { xs: 2, md: 10, lg: 20 }, height: "100%" }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              pl: { xs: 0, md: 5 },
              pr: { xs: 0, md: 2 },
            }}
          >
            <Box
              component="label"
              title="add photo"
              sx={{
                height: { xs: "160px", sm: "200px" },
                width: { xs: "160px", sm: "200px" },
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                mt: 2,
              }}
            >
              <Box
                component="img"
                alt="add image icon"
                src={avatar ?? addImg}
                sx={
                  avatar
                    ? {
                        height: { xs: "160px", sm: "200px" },
                        width: { xs: "160px", sm: "200px" },

                        borderRadius: "10px",
                      }
                    : {
                        height: { xs: "160px", sm: "200px" },
                        width: { xs: "160px", sm: "200px" },

                        // width: { xs: "20px", sm: "40px" },
                      }
                }
              />
              <input
                onChange={(e) => handleChange(e)}
                hidden
                type="file"
                accept="image/jpeg"
              />
            </Box>
            <Typography
              sx={{
                fontSize: "14px",
                color: "white",
                fontWeight: "bold",
                textAlign: "left",
                py: 2,
              }}
            >
              Click To Change Your Picture
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              // px: { md: 5 },
              pl: { md: 17 },
              borderLeft: { md: "2px solid #26B3F0" },
            }}
          >
            <Typography
              sx={{
                color: "white",
                fontWeight: "bold",
                textAlign: "left",
                py: 2,
              }}
            >
              Phone Number
            </Typography>
            <InputField
              name="phone_number"
              formik={formik}
              placeholder={"Enter Your User Name"}
            />
            <Typography
              sx={{
                color: "white",
                fontWeight: "bold",
                textAlign: "left",
                py: 2,
              }}
            >
              User Name
            </Typography>
            <InputField
              name="username"
              formik={formik}
              placeholder={"Enter Your User Name"}
            />

            <Box
              width={"100%"}
              sx={{ mt: { xs: 2, md: 7 } }}
              className={"centerRow"}
            >
              <Button>Update Profile</Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default UpdateProfile;
