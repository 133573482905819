import React from "react";
import { Box, Typography } from "@mui/material";
import visa from "../../../assets/images/visa.png";
import zain from "../../../assets/images/zain.png";
import master from "../../../assets/images/master.png";
import plus from "../../../assets/images/plus.png";
import Subheading from "../../../utils/Subheading";
function PaymentGateways() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        height: { sm: "100%" },
        pb: { lg: 8 },
      }}
      className={"buyerBg"}
      // height="87vh"
      width="100%"
    >
      <Box sx={{ width: { xs: "95%", md: "70%" } }}>
        <Box sx={{ py: { xs: 3 } }} className="centerRow">
          <Subheading>My Payment Gateways</Subheading>
        </Box>
        <Box display="flex" justifyContent={"space-around"}>
          <Box className="centerColumn">
            <Box component="img" src={visa}></Box>
            <Typography>12 Transactions</Typography>
          </Box>
          <Box className="centerColumn">
            <Box component="img" src={zain}></Box>
            <Typography>12 Transactions</Typography>
          </Box>
        </Box>
        <Box sx={{ py: { xs: 3 } }} className="centerRow">
          <Subheading>Add New Gateways</Subheading>
        </Box>
        <Box display="flex" justifyContent={"space-around"}>
          <Box position="relative">
            <Box component="img" src={master}></Box>
            <Box
              sx={{
                width: "35px",
                height: "35px",
                position: "absolute",
                top: -5,
                right: -5,
                cursor: "pointer",
              }}
              component="img"
              src={plus}
            ></Box>
          </Box>
          <Box position="relative">
            <Box component="img" src={master}></Box>
            <Box
              sx={{
                width: "35px",
                height: "35px",
                position: "absolute",
                top: -5,
                right: -5,
                cursor: "pointer",
              }}
              component="img"
              src={plus}
            ></Box>
          </Box>
        </Box>
        <Box className="centerRow">
          <Subheading>We don't store any data for your security</Subheading>
        </Box>
      </Box>
    </Box>
  );
}

export default PaymentGateways;
