import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
// import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import Footer from "../components/Footer/Footer";
import Loader from "../utils/Loader";
const Home = lazy(() => import("../screens/HomeScreen/index"));
const Videos = lazy(() => import("../components/VideosTesting/Videos"));
const SignIn = lazy(() => import("../components/Auth/SignIn"));
const SignUp = lazy(() => import("../components/Auth/SignUp"));
const ForgetPassword = lazy(() => import("../components/Auth/ForgetPassword"));
const ProductDetail = lazy(() =>
  import("../components/ProductDetails/ProductDetails")
);
const TermsConditions = lazy(() =>
  import("../components/TermsConditions/TermsConditions")
);
const AllAuctions = lazy(() => import("../screens/AuctionsScreen/AllAuctions"));
const AuctionResults = lazy(() =>
  import("../screens/AuctionResults/AuctionResults.js")
);

export default function Index() {
  return (
    <>
      <Navbar />
      <React.Suspense fallback={<Loader active={true} />}>
        <Routes>
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/auction/:id" element={<AllAuctions />} />
          <Route exact path="/auction-results" element={<AuctionResults />} />
          <Route exact path="/video" element={<Videos />} />
          <Route path="/product-detail/:id" element={<ProductDetail />} />
          <Route path="/term-conditions" element={<TermsConditions />} />
        </Routes>
      </React.Suspense>
    </>
  );
}
