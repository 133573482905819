import axios from "../axiosConfig";

class Routes {
  addProduct(data) {
    return axios.post("/admin/product", data);
  }
  editProduct(id, data) {
    return axios.put(`/admin/product/${id}`, data);
  }
  deleteProduct(id) {
    return axios.delete(`/admin/product/${id}`);
  }
  allunSoldProducts() {
    return axios.get("/admin/product");
  }
  changeProductStatus(id) {
    return axios.put(`/admin/set-status/${id}`);
  }
  setting(id, data) {
    return axios.put(`/admin/set-product-time/${id}`, data);
  }
  productDetail(id) {
    return axios.get(`/home/product/${id}`);
  }

  //add to favoriat
  addFavoriat(data) {
    return axios.post(`/buyer/favourite`, data);
  }
}

export default new Routes();
