import React from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import userImg from "../../../assets/images/userImg.png";
const { REACT_APP_HOST } = process.env;

function MyBidsCard({ item }) {
  return (
    <div>
      <Box
        className="centerColumn"
        sx={{ py: { xs: 4, md: 3 }, px: { md: 2 } }}
      >
        <Paper
          elevation={12}
          sx={{
            cursor: "pointer",
            // borderRadius: "15%",
            width: { xs: "120px", md: "170px" },
            height: { xs: "120px", md: "170px  " },
            // bgcolor: "rgba(255,255, 255,0.4) !important",
            backgroundImage: `url(${REACT_APP_HOST}${item?.product?.images[0].url})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "10px",
            position: "relative",
            border: "3px solid #B9EDFF",
            p: "1%",
          }}
        >
          <Box
            className="centerColumn"
            sx={{
              width: { xs: "40px", md: "50px" },
              height: { xs: "40px", md: "50px" },
              borderRadius: "50%",
              // bgcolor: "red",
              position: "absolute",
              bgcolor: "rgba(38,179, 240,1) !important",
              transformOrigin: "90% 40% !important",
              top: -20,
              left: -20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              className="centerColumn"
              sx={{ color: "white", fontSize: { xs: 10, md: 13 } }}
            >
              {item?.bid_count}
              <Typography sx={{ color: "white", fontSize: { xs: 10, md: 13 } }}>
                Bids
              </Typography>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: { xs: "flex-end", md: "space-around" },
              alignItems: { xs: "center", md: "flex-end" },
              // bgcolor: "red",
              height: "100%",
            }}
          >
            <Button
              sx={{
                width: { xs: "100%", md: "90%" },
                fontSize: { xs: 8, md: 12 },
                bgcolor: "rgba(5,204, 97,1)  !important",
                mb: { xs: 1, md: 0 },
              }}
              fontFamily="Raleway, sans-serif"
              variant="contained"
            >
              Bid For: {item?.amount}
            </Button>
          </Box>
        </Paper>
        <Typography
          // variant="h5"
          // align="center"
          color="#024861"
          fontFamily="Raleway, sans-serif"
          fontWeight="bold"
          sx={{ fontSize: { xs: 18, md: 16, xl: 22 } }}
        >
          Price: $ {item?.product?.price}
        </Typography>
        <Typography
          // variant="h5"
          // align="center"
          color="#024861"
          fontFamily="Raleway, sans-serif"
          fontWeight="bold"
          sx={{ fontSize: { xs: 18, md: 16, xl: 22 } }}
        >
          Year: {item?.product?.year}
        </Typography>
        <Typography
          // variant="h5"
          // align="center"
          color="#024861"
          fontFamily="Raleway, sans-serif"
          fontWeight="bold"
          sx={{ fontSize: { xs: 18, md: 16, xl: 22 } }}
        >
          Your Bid No: {item?.id}
        </Typography>
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{ width: "50px", height: "50px", mt: 1 }}
            component="img"
            src={userImg}
          ></Box>
          <Typography mt="5px">Seller Name</Typography>
        </Box> */}
      </Box>
    </div>
  );
}

export default MyBidsCard;
