import React from "react";
import { Box, Typography } from "@mui/material";
function Subheading(props) {
  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Raleway !important",
          color: "#fff",
          // textShadow: "0px 0.5px 5px rgba(0,0,0,0.5) ",
          fontSize: { xs: 22, md: 22, lg: 30 },
          pt: { xs: 1, md: 2, xl: 6 },
          pb: { xs: 1, md: 3, xl: 7 },

          textAlign: "left",
        }}
      >
        {props.children}
      </Typography>
    </Box>
  );
}

export default Subheading;
