import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import { Box, Grid } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import { useNavigate } from "react-router-dom";
import { AuthAPI } from "../../api";
import { toast } from "react-toastify";
import LanguageButton from "./LanguageButton";
import translate from "../../utils/Translate";
import { useTranslation } from "react-i18next";

const pages = [
  { name: "HOME", to: "/user/home" },
  { name: "About Us", to: "/user/home/#about-us" },
  { name: "Contact Us", to: "/user/home/#contact-us" },
  // { name: "Auction", to: "/auction-results" },
];
const settings = [
  {
    name: "Dashboard",
    to: "/profile/update-profile",
  },
  {
    name: "Logout",
    to: "/",
  },
];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [user, setUser] = React.useState(null);
  let navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    setUser(user);
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSetting = (to) => {
    window.location.replace(to);
    handleCloseUserMenu();
  };

  function logout() {
    AuthAPI.logout().then((res) => {
      localStorage.removeItem("user");
      toast.success("Logout Successfully");
      navigate("/user/home");
      window.location.reload();
    });
  }

  return (
    <AppBar sx={{ zIndex: 10, boxShadow: "none !important" }} position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}
          {/* <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            LOGO
          </Typography> */}

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page?.name}
                  // onClick={() => navigate(page?.to)}
                  href={page?.to}
                >
                  <Typography textAlign="center">{page?.name}</Typography>
                </MenuItem>
              ))}
              <MenuItem onClick={() => navigate("/auction-results")}>
                <Typography textAlign="center">Auction</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Grid container display="flex">
            <Grid item xs={6} md={2} lg={5}>
              {/* <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} /> */}
              {/* <Typography
                variant="h5"
                noWrap
                component="a"
                href=""
                sx={{
                  mr: 2,
                  display: { xs: "flex", md: "none" },
                  flexGrow: 1,
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                LOGO
              </Typography> */}
            </Grid>
            <Grid
              item
              xs={6}
              md={10}
              lg={7}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                  justifyContent: "flex-end",
                }}
              >
                {/* {pages.map((page) => (
                  <Button
                    key={page?.name}
                    onClick={() => navigate(page?.to)}
                    // href={page?.to}
                    sx={{
                      my: 2,
                      color: "white",
                      display: "block",
                      fontWeight: "bold",
                      fontFamily: " Raleway, sans-serif",
                      fontSize: { md: "16px" },
                      fontWeight: 400,
                      lineHeight: "21px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    {page?.name}
                  </Button>
                ))} */}
                <Button
                  // key={page?.name}
                  onClick={() => navigate("/user/home")}
                  // href={page?.to}
                  sx={{
                    my: 2,
                    color: "white",
                    display: "block",
                    fontWeight: "bold",
                    fontFamily: " Raleway, sans-serif",
                    fontSize: { md: "16px" },
                    fontWeight: 400,
                    lineHeight: "21px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  {translate("Home")}
                </Button>
                <Button
                  // key={page?.name}
                  onClick={() => navigate("/user/home/#about-us")}
                  href={"/user/home/#about-us"}
                  sx={{
                    my: 2,
                    color: "white",
                    display: "block",
                    fontWeight: "bold",
                    fontFamily: " Raleway, sans-serif",
                    fontSize: { md: "16px" },
                    fontWeight: 400,
                    lineHeight: "21px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  {translate("About Us")}
                </Button>
                <Button
                  // key={page?.name}
                  onClick={() => navigate("/user/home/#contact-us")}
                  href={"/user/home/#contact-us"}
                  sx={{
                    my: 2,
                    color: "white",
                    display: "block",
                    fontWeight: "bold",
                    fontFamily: " Raleway, sans-serif",
                    fontSize: { md: "16px" },
                    fontWeight: 400,
                    lineHeight: "21px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  {translate("Contact Us")}
                </Button>

                <Button
                  onClick={() => navigate("/user/auction-results")}
                  // href={page?.to}
                  sx={{
                    my: 2,
                    color: "white",
                    display: "block",
                    fontWeight: "bold",
                    fontFamily: " Raleway, sans-serif",
                    fontSize: { md: "16px" },
                    fontWeight: 400,
                    lineHeight: "21px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  {translate("Auction")}
                </Button>
              </Box>

              <Box
                sx={{
                  flexGrow: 0,
                  display: "flex",
                  justifyContent: { xs: "flex-end", md: "center" },
                }}
              >
                {user?.access_token ? (
                  <>
                    <Tooltip title="Open settings">
                      <IconButton
                        onClick={handleOpenUserMenu}
                        sx={{ p: 0, mx: { xs: 0, md: 2 } }}
                      >
                        <AccountCircleSharpIcon
                          sx={{ fontSize: { xs: 30, md: 40 } }}
                        />
                        {/* <Avatar
                      alt="Remy Sharp"
                      src="/static/images/avatar/2.jpg"
                    /> */}
                      </IconButton>
                    </Tooltip>
                    <Menu
                      sx={{ mt: "45px" }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      {/* {settings.map((setting) => ( */}
                      <MenuItem
                        // key={setting.name}
                        onClick={() => {
                          handleCloseUserMenu();
                          navigate("/profile/update-profile");
                        }}
                      >
                        <Typography textAlign="center">
                          {/* {setting.name} */}
                          {translate("Dashboard")}
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleCloseUserMenu();
                          logout();
                        }}
                      >
                        <Typography textAlign="center">
                          {t("logout")}
                          {/* {translate("Logout")} */}
                        </Typography>
                      </MenuItem>
                      {/* // ))} */}
                    </Menu>
                  </>
                ) : (
                  // http://iraqauctionadmin.geeklone.com/auth/signin
                  <Button
                    onClick={() => navigate("/auth/signin")}
                    sx={{
                      // my: 2,
                      color: "white",
                      display: { xs: "none", md: "block" },
                      fontWeight: "bold",
                      fontFamily: " Raleway, sans-serif",
                      fontSize: { md: "16px" },
                      fontWeight: 400,
                      lineHeight: "21px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    {translate("Login")}
                  </Button>
                )}
                {/* <Button
                  // onClick={handleCloseNavMenu}
                  sx={{
                    // my: 2,
                    color: "white",
                    display: { xs: "none", md: "block" },
                    fontWeight: "bold",
                    fontFamily: " Raleway, sans-serif",
                    fontSize: { md: "16px" },
                    fontWeight: 400,
                    lineHeight: "21px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  EN
                </Button> */}
                <LanguageButton />

                {/* <Button
                  // onClick={handleCloseNavMenu}
                  sx={{
                    // my: 2,
                    color: "white",
                    display: { xs: "none", md: "block" },
                    fontWeight: "bold",
                    fontFamily: " Raleway, sans-serif",
                    fontSize: { md: "16px" },
                    fontWeight: 400,
                    lineHeight: "21px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  Arabic
                </Button> */}
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
