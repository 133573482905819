import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Title from "../../../utils/Subheading";
import Carousel from "../../../utils/Carousel";
import MyBidsCard from "./MyBidsCard";
import cat1 from "../../../assets/images/cat1.png";
import { BuyerAPI } from "../../../api";
import { Typography } from "@mui/material";
import Loader from "../../../utils/Loader";

const WonBids = [
  {
    id: 1,
    title: "item #1",
    image: cat1,
    count: "22",
    bidNo: "22",
    amount: "10000",
    price: "10000",
    year: "2002",
  },
  {
    id: 2,
    title: "item #2",
    image: cat1,
    count: "4",
    price: "20000",
    year: "2016",
    bidNo: "22",
    amount: "10000",
  },
  {
    id: 3,
    title: "item #3",
    image: cat1,
    count: "65",
    price: "700",
    year: "2020",
    bidNo: "22",
    amount: "10000",
  },
  {
    id: 4,
    title: "item #4",
    image: cat1,
    count: "70",
    price: "9000",
    year: "2002",
    bidNo: "22",
    amount: "10000",
  },
  {
    id: 5,
    title: "item #5",
    image: cat1,
    count: "1",
    price: "3000",
    year: "2009",
    bidNo: "22",
    amount: "10000",
  },
];

function MyBids() {
  const [loading, setLoading] = useState(true);
  const [mybids, setmybids] = useState([]);

  useEffect(() => {
    BuyerAPI.buyerAllBids().then((res) => {
      setmybids(res?.data?.data);
      setLoading(false);
    });
  }, []);
  return (
    <Box
      className={"buyerBg"}
      sx={{
        px: { xs: 4, md: 0 },
        display: "flex",
        alignItems: "center",
        width: "100%",
        // pb: { xs: 6, sm: 12, md: 1 },
        height: { sm: "90vh" },
      }}
    >
      <Loader active={loading} />
      <Box width="100%">
        <Box className={"centerRow"} width="100% ">
          <Title>My Bids</Title>
        </Box>
        <Box>
          {mybids?.length > 0 ? (
            <Carousel>
              {mybids?.map((item) => (
                <MyBidsCard item={item}></MyBidsCard>
              ))}
            </Carousel>
          ) : (
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "18px",
                py: 12,
                width: "100%",
                textAlign: "center",
                color: "#7ba1a6",
              }}
            >
              No Bids Found
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default MyBids;
