import React, { useState, useEffect } from "react";
import Layout from "./../components/Buyer/Layout/Layout";
import BuyerRoutes from "../routes/Buyer/BuyerRoutes";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
export default function Buyer() {
  const [user, setUser] = useState(false);
  let navigate = useNavigate();
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    console.log("user", user);
    if (user?.role) {
      setUser(true);
    } else {
      setUser(false);
    }
  }, [user]);
  return (
    <>
      {user ? (
        <Box className="buyerBg">
          <Layout>
            <BuyerRoutes />
          </Layout>
        </Box>
      ) : (
        <>{navigate("/user/home")}</>
      )}
    </>
  );
}
