import * as React from "react";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";

export default function LanguageButton() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    // console.log("language", localStorage.getItem("i18nextLng"));
    // setOpen((prevOpen) => !prevOpen);
    handleLanguage();
    // let lang = localStorage.getItem("i18nextLng");
    // if (lang == "en") {
    //   localStorage.setItem("i18nextLng", "ar");
    // }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  const { t, i18n } = useTranslation();

  const handleLanguage = (lang) => {
    let langv = localStorage.getItem("i18nextLng");
    if (langv == "en-US") {
      i18n.changeLanguage("ar");
    }
    if (langv == "en") {
      i18n.changeLanguage("ar");
    }
    if (langv == "ar") {
      i18n.changeLanguage("en");
    }
  };

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Stack direction="row" spacing={2}>
      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          {localStorage.getItem("i18nextLng") === "en-US" ? (
            <Typography sx={{ color: "white", fontWeight: "bold" }}>
              ENG
            </Typography>
          ) : localStorage.getItem("i18nextLng") === "en" ? (
            <Typography sx={{ color: "white", fontWeight: "bold" }}>
              ENG
            </Typography>
          ) : (
            <Typography sx={{ color: "white", fontWeight: "bold" }}>
              AR
            </Typography>
          )}
        </Button>
        <Popper
          sx={{ zIndex: 9999 }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              sx={{ zIndex: 9999 }}
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper sx={{ zIndex: 9999, background: "red" }}>
                <ClickAwayListener
                  sx={{ background: "yellow" }}
                  onClickAway={handleClose}
                >
                  <MenuList
                    sx={{ zIndex: 9999 }}
                    fullWidth
                    autoFocusItem={open}
                    id="composition-menu"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      onClick={(e) => {
                        handleClose(e);
                        handleLanguage("en");
                      }}
                    >
                      EN
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => {
                        handleClose(e);
                        handleLanguage("ar");
                      }}
                    >
                      AR
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
}
