import React, { lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import UserRoutes from "./UserRoutes";
import AuthRoutes from "./AuthRoutes";
import ProfileRoutes from "../screens/Buyer";
import Loader from "../utils/Loader";

export default function Index() {
  return (
    <>
      <React.Suspense fallback={<Loader active={true} />}>
        <Routes>
          <Route
            exact
            path="/"
            element={<Navigate replace to="/user/home" />}
          />
          <Route exact path="/auth/*" element={<AuthRoutes />} />
          <Route exact path="/user/*" element={<UserRoutes />} />
          <Route exact path="/profile/*" element={<ProfileRoutes />} />
        </Routes>
      </React.Suspense>
    </>
  );
}
