import React from "react";
import { Box, TextField } from "@mui/material";
function InputField({
  size,
  formik,
  label = "",
  name,
  noValidationText,
  sx,
  ...props
}) {
  return (
    <Box>
      <TextField
        placeholder={props.placeholder}
        variant="outlined"
        fontFamily="Raleway, sans-serif"
        sx={{
          width: "100%",
          border: "none",
          outline: "none",

          boxShadow: "0px 0px 8px 0px rgba(50, 50, 50, 0.5)",
          ".MuiOutlinedInput-root": {
            outline: "none",
          },
          ".MuiInputBase-input": {
            bgcolor: "#26B3F0",
            color: "#ffff",
            "::placeholder ": {
              color: "#fff",
              fontFamily: "Raleway, sans-serif !important",
            },
            fontFamily: "Raleway, sans-serif",
            py: 1,
          },
        }}
        {...props}
        id={name}
        name={name}
        label={label}
        value={formik?.values[name]}
        onChange={formik?.handleChange}
        onBlur={formik?.handleBlur}
        error={formik?.touched[name] && Boolean(formik?.errors[name])}
        helperText={
          !noValidationText && formik?.touched[name] && formik?.errors[name]
        }
      />
    </Box>
  );
}

export default InputField;
