import React from "react";
import { Box, Button } from "@mui/material";

function ButtonN(props) {
  return (
    <Box>
      <Button
        variant="contained"
        sx={{
          fontFamily: "poppins",
          boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.6)",
          px: 3,
          py: 1,
          backgroundColor: "#26B3F0",
          color: "white",
          width: { md: "100%" },
          "&:hover": {
            transform: "scale(1.1)",
            bgcolor: "#FFB501",
          },
          fontSize: 14,
        }}
        type="submit"
        onClick={props.onClick}
      >
        {props.children}
      </Button>
    </Box>
  );
}

export default ButtonN;
