import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";

import Profile from "../../components/Buyer/Profile/Profile.js";
import UpdateProfile from "../../components/Buyer/Profile/UpdateProfile";
import MyBids from "../../components/Buyer/MyBids/MyBids";
import PaymentGateways from "../../components/Buyer/PaymentGateways/PaymentGateways";
import WatchingBids from "../../components/Buyer/WatchingBids/WatchingBids";
import Loader from "../../utils/Loader";
const MyPurchases = lazy(() =>
  import("../../components/Buyer/MyPurchases/MyPurchases")
);

function BuyerRoutes() {
  return (
    <React.Suspense fallback={<Loader active={true} />}>
      <Routes>
        <Route path="/" element={<Profile />} />
        <Route path="/update-profile" element={<UpdateProfile />} />
        <Route path="/my-purchases" element={<MyPurchases />} />
        <Route path="/my-bids" element={<MyBids />} />
        <Route path="/payment-gateways" element={<PaymentGateways />} />
        <Route path="/watching-bids" element={<WatchingBids />} />
      </Routes>
    </React.Suspense>
  );
}
export default BuyerRoutes;
