import axios from "../axiosConfig";

class Routes {
  wonBids() {
    return axios.get("/admin/won/bids");
  }
  //user add bid
  addBids(data) {
    return axios.post(
      `/buyer/store/bid?bid_amount=${data.amount}&product_id=${data.b_Id}`
    );
  }
}

export default new Routes();
