import axios from "../axiosConfig";

class Routes {
  cities() {
    return axios.get("/cities");
  }

  contactUs(data) {
    return axios.post("/contact-us", data);
  }
  //FAQ
  getFAQs() {
    return axios.get("/faq/list");
  }
  //search by product title
  searchByTitle(title) {
    return axios.get(`/search/records?title=${title}`);
  }
  //search by product year
  searchByYear(year) {
    return axios.get(`/search/records/${year}`);
  }
  //search by product price
  searchByPrice(price) {
    return axios.get(`/search/${price}`);
  }

  //Get Home Content
  getHomeContent() {
    return axios.get("/home");
  }
}

export default new Routes();
