import { useTranslation } from "react-i18next";

function Translate(string) {
  const { t } = useTranslation();
  var sentence = string?.toLowerCase()?.split(" ");
  for (var i = 0; i < sentence?.length; i++) {
    sentence[i] = sentence[i][0]?.toLowerCase() + sentence[i]?.slice(1);
  }
  let value = sentence?.join("_");
  return t(value);
}

export default Translate;
