import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";
import Subheading from "../../../utils/Subheading";
import Residence from "../../../assets/images/Residence.png";
import Commercial from "../../../assets/images/Commercial.png";
import car from "../../../assets/images/car.png";
import shop from "../../../assets/images/shop.png";
import genral from "../../../assets/images/genral.png";
import mechine from "../../../assets/images/mechine.png";
import { useNavigate } from "react-router-dom";
import BidsCard from "./WatchingBidsCard";
import Carousel from "../../../utils/Carousel";
import cat1 from "../../../assets/images/cat1.png";
import SouthIcon from "@mui/icons-material/South";
import userImg from "../../../assets/images/userImg.png";
import Loader from "../../../utils/Loader";
import { BuyerAPI } from "../../../api";
const { REACT_APP_HOST } = process.env;

let Cats = [
  {
    image: Commercial,
    title: "Commercial",
    count: "99+",
  },
  {
    image: Residence,
    title: "Residence",
    count: "233",
  },

  {
    image: car,
    title: "Cars",
    count: "12",
  },
  {
    image: shop,
    title: "Shops",
    count: "99",
  },
  {
    image: genral,
    title: "Genral Items",
    count: "233",
  },
  {
    image: mechine,
    title: "Mechinery",
    count: "1",
  },
];
const WonBids = [
  {
    id: 1,
    title: "item #1",
    image: cat1,
    count: "22",
    bidNo: "22",
    amount: "10000",
    price: "10000",
    year: "2002",
  },
  {
    id: 2,
    title: "item #2",
    image: cat1,
    count: "4",
    price: "20000",
    year: "2016",
    bidNo: "22",
    amount: "10000",
  },
  {
    id: 3,
    title: "item #3",
    image: cat1,
    count: "65",
    price: "700",
    year: "2020",
    bidNo: "22",
    amount: "10000",
  },
  {
    id: 4,
    title: "item #4",
    image: cat1,
    count: "70",
    price: "9000",
    year: "2002",
    bidNo: "22",
    amount: "10000",
  },
  {
    id: 5,
    title: "item #5",
    image: cat1,
    count: "1",
    price: "3000",
    year: "2009",
    bidNo: "22",
    amount: "10000",
  },
];
function WatchingBids() {
  let navigate = useNavigate();

  const [selectedCard, setSelectedCard] = useState(0);
  const [status, setStatus] = useState(1);
  const [selected, setSelected] = useState(1);
  const [categories, setCategories] = useState([]);
  const [productsByCat, setProductsByCat] = useState([]);
  const [selectedProductBids, setselectedProductBids] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [checked, setChecked] = useState();
  const [change, setChange] = useState(false);
  const [userId, setUserid] = useState(null);

  useEffect(() => {
    BuyerAPI.buyerFavourteBids().then((res) => {
      setCategories(res?.data?.data);
      let newArr = res?.data.data?.filter((item) => item.id == selected);
      let newData = newArr[0]?.products?.filter(
        (product) => product.status == status
      );
      setProductsByCat(newData);
      setisLoading(false);
      setselectedProductBids(newData[0]?.bids);
      setSelectedCard(newData[0]?.id);
    });
    let user = JSON.parse(localStorage.getItem("user"));
    setUserid(user.id);
  }, []);

  useEffect(() => {
    let newArr = categories?.filter((item) => item.id == selected);
    let newData = newArr[0]?.products?.filter(
      (product) => product.status == status
    );
    setProductsByCat(newData);
    console.log("newData", newData);
    setselectedProductBids(newData?.length ? newData[0]?.bids : []);
    setChange(false);
  }, [selected]);

  return (
    <div>
      <Box sx={{ px: { xs: 4, md: 0 } }}>
        <Box
          className={"centerRow"}
          sx={{ py: { md: 1 }, pb: { xs: 2, md: 0 } }}
        >
          <Subheading>Categories</Subheading>
        </Box>
        <Loader active={isLoading} />
        <Grid container spacing={2} px={"5%"}>
          {categories?.map((cat, index) => {
            return (
              <Grid
                item
                key={cat.name}
                xs={6}
                sm={4}
                md={4}
                lg={2}
                display="flex"
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems="center"
              >
                <Paper
                  elevation={3}
                  sx={{
                    cursor: "pointer",
                    width: { xs: "80px", md: "120px" },
                    height: { xs: "80px", md: "120px  " },
                    bgcolor:
                      selected == cat?.id
                        ? "rgba(61,197, 255,0.75) "
                        : "rgba(158,228, 253,0.4) !important",
                    borderRadius: "10px",
                    position: "relative",
                    p: "5%",
                  }}
                  onClick={() => {
                    // navigate("/auction");
                    setSelected(cat?.id);
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: "40px", md: "50px" },
                      height: { xs: "40px", md: "50px" },
                      borderRadius: "50%",
                      bgcolor: "red",
                      position: "absolute",
                      bgcolor: "rgba(38,179, 240,1) !important",
                      transformOrigin: "90% 40% !important",
                      top: -20,
                      right: -20,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: 18, md: 16, xl: 22 },
                        color: "white",
                      }}
                    >
                      {cat?.products?.length}
                    </Typography>
                  </Box>
                  <Box
                    component="img"
                    sx={{
                      width: "100%",
                      height: "100%",
                      // width: { xs: "50%", sm: "60%", md: "100%" },
                      // heigth: { xs: "50%", sm: "60%", md: "100%" },
                      display: "block",
                      overflow: "hidden",
                      objectFit: "contain",
                    }}
                    src={`${REACT_APP_HOST}${cat?.image?.url}`}
                  />
                </Paper>
                <Typography
                  // variant="h5"
                  // align="center"
                  color="#024861"
                  fontFamily="Raleway, sans-serif"
                  fontWeight="bold"
                  sx={{ fontSize: { xs: 18, md: 16, xl: 22 }, py: 2 }}
                >
                  {cat?.name}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
        <Box>
          <Carousel>
            {productsByCat?.length > 0 ? (
              productsByCat.map((item, index) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <BidsCard
                    item={item}
                    index={index}
                    setSelectedCard={setSelectedCard}
                    setselectedProductBids={setselectedProductBids}
                  ></BidsCard>
                  <Box
                    sx={{ display: selectedCard == item.id ? "block" : "none" }}
                    className="centerRow"
                  >
                    <SouthIcon color="error" />
                  </Box>
                </Box>
              ))
            ) : (
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  py: 12,
                  color: "#7ba1a6",
                }}
              >
                No Watching products in this category
              </Typography>
            )}
          </Carousel>
        </Box>
        <Box>
          {selectedProductBids?.length > 0 ? (
            selectedProductBids?.map((item, index) => {
              return (
                <Grid
                  container
                  sx={{ px: { md: 8 } }}
                  position="relative"
                  className="centerRow"
                >
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justiftContent: "center",
                      alignItems: "center",
                    }}
                    width="100%"
                    item
                    xs={12}
                    md={2}
                  >
                    <Box
                      sx={{ width: "50px", height: "50px", mt: 1 }}
                      component="img"
                      src={
                        item?.user?.image?.url
                          ? `${REACT_APP_HOST}${item?.user?.image?.url}`
                          : userImg
                      }
                    ></Box>
                    <Typography
                      mt="5px"
                      sx={{ fontSize: { xs: 10, sm: 12, md: 14, lg: 18 } }}
                    >
                      {item?.user?.username}
                    </Typography>
                  </Grid>
                  <Grid
                    bgcolor={
                      item.user?.id == userId
                        ? "#FFB501"
                        : "rgba(38,179, 240,1)"
                    }
                    display="flex"
                    justifyContent="space-around"
                    alignItems="center"
                    position="relative"
                    item
                    xs={12}
                    md={10}
                    sx={{
                      my: { xs: 2, md: 2 },
                      py: { xs: 1, sm: 2 },
                      borderRadius: { xs: "4px", md: "20px" },
                    }}
                  >
                    <Typography
                      color={"white"}
                      sx={{ fontSize: { xs: 10, sm: 12, md: 14, lg: 18 } }}
                      fontFamily="Raleway, sans-serif"
                    >
                      {item?.user?.username}
                    </Typography>
                    <Typography
                      color={"white"}
                      sx={{ fontSize: { xs: 10, sm: 12, md: 14, lg: 18 } }}
                      fontFamily="Raleway, sans-serif"
                    >
                      Bid Number: {item.id}
                    </Typography>
                    <Typography
                      color={"white"}
                      sx={{ fontSize: { xs: 10, sm: 12, md: 14, lg: 18 } }}
                      fontFamily="Raleway, sans-serif"
                    >
                      Bid Amount: {item.amount}
                    </Typography>
                    <Box
                      bgcolor="rgba(5,204, 97,1)"
                      position="absolute"
                      right={7}
                      display={item.user?.id == userId ? "block" : "none"}
                      sx={{
                        top: { xs: -8, sm: -18, md: -25 },
                        width: { xs: "60px", md: "120px" },
                        py: { xs: 0, sm: 1 },
                        color: "white",
                        borderRadius: "12px",
                        fontSize: { xs: 10, sm: 12, md: 14, lg: 18 },
                      }}
                    >
                      <Typography textAlign="center">Your Bid</Typography>
                    </Box>
                  </Grid>
                </Grid>
              );
            })
          ) : productsByCat?.length ? (
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "18px",
                py: 12,
                textAlign: "center",
                color: "#7ba1a6",
              }}
            >
              No Bids Yet
            </Typography>
          ) : null}
        </Box>
      </Box>
    </div>
  );
}

export default WatchingBids;
