import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import Title from "../../../utils/Subheading";
import InputField from "../../../utils/InputField";
import PasswordField from "../../../utils/PasswordField";
import Button from "../../../utils/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { OutlinedInput, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { BuyerAPI } from "../../../api";
function Profile() {
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const [email, setEmail] = useState(null);
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    setEmail(user.email);
  }, []);
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const initialEmailValues = {
    old_email: "",
    email: "",
  };
  const initialPassValues = {
    old_password: "",
    password: "",
    password_confirmation: "",
  };

  const emailValidationSchema = Yup.object().shape({
    old_email: Yup.string().required("Older Email is required"),
    email: Yup.string().required("New Email is required"),
  });
  const passValidationSchema = Yup.object().shape({
    old_password: Yup.string().required("Older Password is required"),
    password: Yup.string().required("New Password is required"),
    password_confirmation: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const e_formik = useFormik({
    initialValues: initialEmailValues,
    validationSchema: emailValidationSchema,
    onSubmit: onSubmitEmail,
  });
  const p_formik = useFormik({
    initialValues: initialPassValues,
    validationSchema: passValidationSchema,
    onSubmit: onSubmitPass,
  });

  function onSubmitEmail(values) {
    console.log("email values", values);
    BuyerAPI.buyerChangeEmail(values).then((res) => console.log("res", res));
  }
  function onSubmitPass(values) {
    console.log("pass values", values);
    values.email = email;
    BuyerAPI.buyerChangePass(values).then((res) => console.log("res", res));
  }

  return (
    <Box
      sx={{
        pt: { xs: 4, md: 10 },
        // pb: { xs: 6, sm: 10, md: 9 }
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{ px: { xs: 2, md: 10, lg: 20 }, height: "100%" }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            pl: { xs: 0, md: 5 },
            pr: { xs: 0, md: 2 },
          }}
        >
          <Box component="form" onSubmit={e_formik.handleSubmit}>
            <Title>Current Email</Title>
            <InputField
              name="old_email"
              formik={e_formik}
              placeholder={"Enter Your Current Email"}
            />
            <Title>Change Email</Title>
            <InputField
              name="email"
              formik={e_formik}
              placeholder={"Enter Your New Email"}
            />
            <Box
              width={"100%"}
              sx={{ mt: { xs: 2, md: 15 } }}
              className={"centerRow"}
            >
              <Button onClick={() => e_formik.onSubmit()}>Change Email</Button>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            // px: { md: 5 },
            pl: { md: 17 },
            borderLeft: { md: "2px solid #26B3F0" },
          }}
        >
          <Box component="form" onSubmit={p_formik.handleSubmit}>
            <Title>Current Password</Title>
            <OutlinedInput
              sx={{
                // ...props.sx,
                bgcolor: "#26B3F0",
                width: "100%",
                boxShadow: "0px 0px 8px 0px rgba(50, 50, 50, 0.5)",
                ".MuiInputBase-input": {
                  color: "#ffff",
                  "::placeholder ": {
                    color: "#ffff",
                    fontFamily: "Raleway, sans-serif",
                  },
                  fontFamily: "Raleway, sans-serif",
                  py: 1,
                },
              }}
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              // value={values.password}
              // placeholder={props.placeholder}
              // onChange={handleChange("password")}

              placeholder={"Enter Your Current Password"}
              name={"old_password"}
              label={"old_password"}
              value={p_formik?.values.old_password}
              onChange={p_formik?.handleChange}
              onBlur={p_formik?.handleBlur}
              error={
                p_formik?.touched.old_password &&
                Boolean(p_formik?.errors.old_password)
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      color: "#fff",
                    }}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />

            {/* <PasswordField
              name="old_password"
              formik={p_formik}
              placeholder={"Enter Your Current Password"}
            /> */}
            <Title>Change Password</Title>
            <OutlinedInput
              sx={{
                // ...props.sx,
                bgcolor: "#26B3F0",
                width: "100%",
                boxShadow: "0px 0px 8px 0px rgba(50, 50, 50, 0.5)",
                ".MuiInputBase-input": {
                  color: "#ffff",
                  "::placeholder ": {
                    color: "#ffff",
                    fontFamily: "Raleway, sans-serif",
                  },
                  fontFamily: "Raleway, sans-serif",
                  py: 1,
                },
              }}
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              // value={values.password}
              // placeholder={props.placeholder}
              // onChange={handleChange("password")}

              placeholder={"New Password"}
              name={"password"}
              label={"password"}
              value={p_formik?.values.password}
              onChange={p_formik?.handleChange}
              onBlur={p_formik?.handleBlur}
              error={
                p_formik?.touched.password && Boolean(p_formik?.errors.password)
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      color: "#fff",
                    }}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />

            <OutlinedInput
              sx={{
                mt: 3,
                // ...props.sx,
                bgcolor: "#26B3F0",
                width: "100%",
                boxShadow: "0px 0px 8px 0px rgba(50, 50, 50, 0.5)",
                ".MuiInputBase-input": {
                  color: "#ffff",
                  "::placeholder ": {
                    color: "#ffff",
                    fontFamily: "Raleway, sans-serif",
                  },
                  fontFamily: "Raleway, sans-serif",
                  py: 1,
                },
              }}
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              // value={values.password}
              // placeholder={props.placeholder}
              // onChange={handleChange("password")}

              placeholder={"Confirm Password"}
              name={"password_confirmation"}
              label={"password_confirmation"}
              value={p_formik?.values.password_confirmation}
              onChange={p_formik?.handleChange}
              onBlur={p_formik?.handleBlur}
              error={
                p_formik?.touched.password_confirmation &&
                Boolean(p_formik?.errors.password_confirmation)
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      color: "#fff",
                    }}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />

            {/* <PasswordField
              name="password"
              formik={p_formik}
              placeholder={"New Password"}
            />
            <PasswordField
              name="password_confirmation"
              formik={p_formik}
              sx={{ mt: 5 }}
              placeholder={"Confirm Password"}
            /> */}
            <Box
              width={"100%"}
              sx={{ mt: { xs: 2, md: 7 } }}
              className={"centerRow"}
            >
              <Button>Change Password</Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Profile;
