import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import { Container } from "@mui/system";
import image from "../../assets/images/Vector.png";
import translate from "../../utils/Translate";

function Footer() {
  return (
    <Box position="relative" height={"100%"}>
      <Grid
        container
        spacing={2}
        sx={{ backgroundColor: "lightblue", bottom: 0, position: "absolute" }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", justifyContent: { xs: "center" } }}
        >
          <Typography
            color="#024861"
            sx={{ fontSize: { xs: 8, sm: 18, md: 20, lg: 22 } }}
            fontFamily="Raleway, sans-serif"
          >
            {translate("all_copy_rights")}
            {/* All copyrights reserved by © IRAQ AUCTION */}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: { xs: "space-around", md: "flex-end" },
          }}
        >
          <Typography
            color="#024861"
            sx={{ fontSize: { xs: 8, sm: 18, md: 20, lg: 22 } }}
            fontFamily="Raleway, sans-serif"
          >
            {translate("Privacy_Policy")}
            {/* Privacy Policy &nbsp; */}
          </Typography>
          {/* <Typography
            color="#024861"
            sx={{ fontSize: { xs: 8, sm: 18, md: 20, lg: 22 } }}
            fontFamily="Raleway, sans-serif"
          >
            Term & conditions
          </Typography> */}
          <Box px={2} sx={{ width: { xs: 20, md: 40 } }}>
            <img width={"100%"} src={image} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Footer;
